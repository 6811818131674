export const AccesoBackOfficeOptions = [
  "Asistencias",
  "Clientes",
  "Proveedores",
  "TiposAsistencia",
  "Pagos",
  "Usuarios",
  "CambioEspecial",
  "Calidad",
  "AltaProveedores",
  "DescargarProveedores",
  "AdminLibrary", // Permite subir archivos a la librería en cabina
  "CostoMayor", // Permite cambiar el costo de una asistencia a uno menor // Para el costo menor todos pueden actualmente
  "CambioProveedor",
];

export const ACCION_EXITOSA = "Accion Exitosa";
export const EERROR_ENVIO = "Error de Envío";

export const cuentaEspecialGNPOpciones = [
  { texto: "DACERO", valor: "DACERO" },
  { texto: "DANONE", valor: "DANONE" },
  { texto: "EL ORO", valor: "EL ORO" },
  { texto: "PFS", valor: "PFS" },
  { texto: "SHCP", valor: "SHCP" },
  { texto: "RENT A MATIC ITZA", valor: "RENT A MATIC ITZA" },
  { text: "TEQUILA SAUZA", valor: "TEQUILA SAUZA" },
  { texto: "AUTOFLETES CHIHUAHUA", valor: "AUTOFLETES CHIHUAHUA" },
  { texto: "ELEMENT", valor: "ELEMENT" },
  { text: "CONSERVAS LA COSTEÑA", valor: "CONSERVAS LA COSTEÑA" },
  { text: "ALPURA", valor: "ALPURA" },
];

export const tipoCampo = [
  { texto: "texto", valor: "texto" },
  { texto: "lista", valor: "lista" },
];

export const siNo = [
  { texto: "Si", valor: true },
  { texto: "No", valor: false },
];

export const plOptions = [
  { label: "PL 1", value: "geo1" },
  { label: "PL 2", value: "geo2" },
];

export const MAPBOX_TOKEN =
  "pk.eyJ1IjoicGF0OTAwMCIsImEiOiJjbDI1ajk2cDkwMmNqM2tsZTE2ZWEyZTVrIn0.456pSXyxhu6zvPolqHsdtQ";

export const mesesOptions = [
  { texto: "Ene", valor: 1 },
  { texto: "Feb", valor: 2 },
  { texto: "Mar", valor: 3 },
  { texto: "Abr", valor: 4 },
  { texto: "May", valor: 5 },
  { texto: "Jun", valor: 6 },
  { texto: "Jul", valor: 7 },
  { texto: "Ago", valor: 8 },
  { texto: "Sep", valor: 9 },
  { texto: "Oct", valor: 10 },
  { texto: "Nov", valor: 11 },
  { texto: "Dic", valor: 12 },
];

export const estatusOptions = [
  { texto: "Concluido", valor: 2 },
  { texto: "Otorgado", valor: 3 },
  { texto: "Cancelado con costo", valor: 4 },
  { texto: "Cancelado sin costo", valor: 5 },
];

export const estatusCanceladosOptions = [
  { texto: "Cancelado con costo", valor: 4 },
  { texto: "Cancelado sin costo", valor: 5 },
];

export const cambioConclusion = [
  "ricardo.perez@escotel.com.mx",
  "pat9000@hotmail.com",
  "miguel.morales@escotel.com.mx",
  "jennifer.perez@escotel.com.mx",
];

export const emailCambiarProveedor = ["ricardo.perez@escotel.com.mx", "miguel.morales@escotel.com.mx", "jennifer.perez@escotel.com.mx", "pat9000@hotmail.com"]
export const correosCabiarCosto = ["ricardo.perez@escotel.com.mx", "angel.aguirre@escotel.com.mx", "pat9000@hotmail.com", "jose.almaguer@escotel.com.mx"]